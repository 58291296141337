<template>
    <div id="volunteer_section">
        <router-view></router-view>
        <div id="volunteer_section_content">
            <div id="volunteer_section_logo">
                <img src="@/assets/icons/logo.png" alt="" class="w-10 sm:w-6 md:w-8 lg:w-10">
                <div class="ml-3 font-bold text-3xl font-koho text-white sm:text-xl md:text-2xl lg:text-3xl">STRONG <span class=" text-inputBorderGray">UNITED</span></div>
            </div>
            <div id="volunteer_section_sub_para">
                The Covid-19 pandemic has been has affected everyone in India one way or the other. 
                <br>Many have lost their jobs. Some have seen their income shrinking. Many are well-qualified, but 
                had chosen before the pandemic to stay home for personal reasons.
                <br><span class="font-medium">Everyone is now forced to look for jobs and other sources of income.</span>
                <br>
                <br>
                The only silver lining is that remote-working is now acceptable to many employers.
                <br>
                <br>
                We are a growing group of volunteers, comprising mostly of professionals, technologists, and 
                students, trying to help. 
                <br>We look at job boards and talk to recruiters at reputable organizations 
                and curate the profiles of individuals that best match the requirements. At no cost.
                <br>None of us collects a salary. We are doing this because we want to help. You can help too - by 
                spreading the word or by helping those around you find gainful employment.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VolunteerSection'
}
</script>

<style>
#volunteer_section{
    @apply w-full h-auto px-5 py-20 bg-mainColor flex flex-col items-center;
    @apply sm:flex-row sm:items-center;
    @apply lg:px-20 
}
#volunteer_section_content{
    @apply mt-20;
    @apply sm:mt-0 sm:ml-10;
    @apply lg:ml-24
}
#volunteer_section_logo{
    @apply flex w-full justify-center items-center;
    @apply sm:justify-start
}
#volunteer_section_sub_para{
    @apply mt-10 font-koho text-white font-extralight leading-loose;
    @apply sm:text-xs sm:mt-6 sm:leading-5;
    @apply md:text-sm md:mt-8 md:leading-6;
    @apply lg:text-base lg:mt-10 lg:leading-7;
}
</style>