import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
const PageNotFound = () => import(/* webpackChunkName: "Registration" */'@/views/PageNotFound') 
import BasicInfo from '@/views/registration/BasicInfo'
const NecessaryHardware = () => import(/* webpackChunkName: "Registration" */'@/views/registration/NecessaryHardware')
const EducationalQualification = () => import(/* webpackChunkName: "Registration" */'@/views/registration/EducationalQualification')
const WorkExperiencePartOne = () => import(/* webpackChunkName: "Registration" */'@/views/registration/WorkExperiencePartOne')
const WorkExperiencePartTwo = () => import(/* webpackChunkName: "Registration" */'@/views/registration/WorkExperiencePartTwo')
const LanguageProficiencySpeak = () => import(/* webpackChunkName: "Registration" */'@/views/registration/LanguageProficiencySpeak')
const LanguageProficiencyReadWrite = () => import(/* webpackChunkName: "Registration" */'@/views/registration/LanguageProficiencyReadWrite')
const PersonalInformation = () => import(/* webpackChunkName: "Registration" */'@/views/registration/PersonalInformation')
const JobPreferences = () => import(/* webpackChunkName: "Registration" */'@/views/registration/JobPreferences')
const Verification = () => import(/* webpackChunkName: "Registration" */'@/views/registration/Verification')
const RegistrationComplete = () => import(/* webpackChunkName: "Registration" */'@/views/registration/RegistrationComplete')
const PrivacyPolicy = () => import(/* webpackChunkName: "Registration" */'@/views/PrivacyPolicy')
const TermsOfUse = () => import(/* webpackChunkName: "Registration" */'@/views/TermsOfUse')
import VolunteerSignUp from '@/components/VolunteerSignUp'
import VolunteerVerification from '@/components/VolunteerVerification'
import VolunteerSuccess from '@/components/VolunteerSuccess'
const ForEmployers = () => import(/* webpackChunkName: "Registration" */'@/views/ForEmployers/ForEmployers')
const ForEmployerVerification = () => import(/* webpackChunkName: "Registration" */'@/views/ForEmployers/ForEmployerVerification')
const ForEmployerBasicInfo = () => import(/* webpackChunkName: "Registration" */'@/views/ForEmployers/ForEmployerBasicInfo')
const ForEmployerEmailVerification = () => import(/* webpackChunkName: "Registration" */'@/views/ForEmployers/ForEmployerEmailVerification')
const JobPosts = () => import(/* webpackChunkName: "Registration" */'@/views/ForEmployers/JobPosts')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        // render signup form as the root child
        path: '/',
        name: 'Home', 
        component: VolunteerSignUp
      },
      {
        // render verification component
        path: 'volunteer/verification',
        component: VolunteerVerification
      },
      {
        // render success page
        path: 'volunteer/success',
        component: VolunteerSuccess
      }
    ]
  },
  {
    path: '/jobseeker_register',
    name: 'BasicInfo',
    component: BasicInfo
  },
  {
    path: '/jobseeker_register/necessary_hardware',
    name: 'NecessaryHardware',
    component: NecessaryHardware
  },
  {
    path: '/jobseeker_register/educational_qualification',
    name: 'EducationalQualification',
    component: EducationalQualification
  },
  {
    path: '/jobseeker_register/work_experience',
    name: 'WorkExperiencePartOne',
    component: WorkExperiencePartOne
  },
  {
    path: '/jobseeker_register/work_experience/job_details',
    name: 'WorkExperiencePartTwo',
    component: WorkExperiencePartTwo
  },
  {
    path: '/jobseeker_register/language_proficiency/speak',
    name: 'LanguageProficiencySpeak',
    component: LanguageProficiencySpeak
  },
  {
    path: '/jobseeker_register/language_proficiency/read_write',
    name: 'LanguageProficiencyReadWrite',
    component: LanguageProficiencyReadWrite
  },
  {
    path: '/jobseeker_register/personal_info',
    name: 'PersonalInformation',
    component: PersonalInformation
  },
  {
    path: '/jobseeker_register/job_preferences',
    name: 'JobPreferences',
    component: JobPreferences
  },
  {
    path: '/jobseeker_register/verification',
    name: 'Verification',
    component: Verification
  },
  {
    path: '/jobseeker_register/registration_complete',
    name: 'RegistartionComplete',
    component: RegistrationComplete
  },
  {
    path: '/privacy_policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms_of_use',
    name: 'TermsOfUse',
    component: TermsOfUse
  },
  {
    path: '/for_employers',
    component: ForEmployers,
    children: [
      {
        // render signup form as the root child
        path: '/',
        name: 'ForEmployers', 
        component: VolunteerSignUp
      },
      {
        // render verification component
        path: 'volunteer/verification',
        component: VolunteerVerification
      },
      {
        // render success page
        path: 'volunteer/success',
        component: VolunteerSuccess
      }
    ]
  },
  {
    path: '/for_employers/verification',
    name: 'ForEmployerVerification',
    component: ForEmployerVerification
  },
  {
    path: '/for_employers/basic_info',
    name: 'ForEmployerBasicInfo',
    component: ForEmployerBasicInfo
  },
  {
    path: '/for_employers/email_verification',
    name: 'ForEmployerEmailVerification',
    component: ForEmployerEmailVerification
  },
  {
    path: '/for_employers/job_posts',
    name: 'JobPosts',
    component: JobPosts
  },
  {
    path: '*',
    name: 'Page Not Found',
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
