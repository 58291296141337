<template>
  <div id="otp_input" :class="{errorStyle: error, verifiedStyle: verified}">
    <v-otp-input
      ref="otpInput"
      input-classes="otp-input"
      separator="<span class='text-inputBorderGray'>-</span>"
      :num-inputs="4"
      :should-auto-focus="true"
      :is-input-num="true"
      @on-complete="handleOnComplete"
      @on-change="handleOnChange"
    />
  </div>
</template>

<script>
const OtpInput = () => import(/* webpackChunkName: "Utils" */ "@bachdgvn/vue-otp-input")

export default {
    name: "OTPInput",
    components: {
        'v-otp-input': OtpInput
    },
    props: {
        error : {
            type: Boolean,
            default: false
        },
        verified : {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleOnComplete: function(value){
            this.$emit('completed', value)
        },
        handleOnChange: function(value){
            this.$emit('change', value)
        }
    }
}
</script>

<style >
#otp_input{
    @apply border border-inputBorderGray inline-block rounded px-2 h-auto
}
.otp-input {
    @apply font-koho text-xl w-5 h-8 my-3 mx-2 text-center;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
-webkit-appearance: none;
    margin: 0;
}
.otp-input .error {
    border: 1px solid red !important;
}
.otp-input:focus{
    @apply outline-none
}
.errorStyle{
    @apply border-customRed !important
}
.verifiedStyle{
    @apply border-mainColor !important
}
</style>