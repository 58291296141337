<template>
  <div
    id="main_section"
    class="flex w-full h-auto justify-center items-end sm:items-center sm:bg-none"
    :style="inlineBgImage"
  >
    <div
      class="flex flex-row w-full mx-5 h-auto sm:h-auto sm:mx-20 lg:mx-20 sm:w-auto sm:pt-12 mb-9 sm:mb-0 justify-center items-center"
    >
      <div
        class="contentLeft h-full w-full max-w-sm lg:max-w-lg sm:w-auto lg:max-h-full bg-white rounded p-7 flex flex-col justify-between sm:justify-evenly lg:pr-30 lg:pl-30"
      >
        <h3
          class="font-koho text-sm text-customRed sm:text-xs md:text-sm lg:text-base"
        >
          A FREE SOCIAL SERVICE INITIATIVE
        </h3>
        <div class="pt-4 md:pt-7 lg:pt-8">
          <h1
            class="font-koho text-5xl text-black font-medium sm:text-4xl md:text-5xl lg:text-7xl lg:font-normal"
          >
            Let's Help
          </h1>
          <h1
            class="font-koho text-5xl text-black font-medium pt-3 sm:pt-1 md:pt-3 lg:pt-5 sm:text-4xl md:text-5xl lg:text-7xl lg:font-normal"
          >
            Each Other
          </h1>
        </div>
        <h3
          class="pt-4 md:pt-7 lg:pt-8 font-koho font-extralight text-base sm:text-sm md:text-base max-w-xs lg:max-w-md lg:text-xl"
        >
          Helping Indian citizens find work-from-home employment with credible
          companies
        </h3>
        <div class="pt-4 md:pt-7 lg:pt-8">
          <RegisterButton :onClick="onClickRegisterJobs" />
        </div>
      </div>
      <div
        class="contentRight h-full w-1/2 lg:w-auto hidden sm:flex items-center sm:max-w-md sm:overflow-hidden"
      >
        <img
          :src="img"
          alt=""
          class="h-auto w-full lg:h-full lg:w-auto object-cover"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RegisterButton from "@/components/RegisterButton";
import API from "@/API";

export default {
  name: "MainSection",
  components: {
    RegisterButton
  },
  data() {
    return {
      img: "",
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    };
  },
  computed: {
    inlineBgImage() {
      if (this.windowWidth <= 638) {
        if (this.windowHeight >= 500) {
          return {
            backgroundImage: `url("${this.img}")`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: `${this.windowHeight}px`
          };
        } else {
          return {
            backgroundImage: `url("${this.img}")`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "auto",
            minHeight: "500px"
          };
        }
      } else {
        if (this.windowHeight >= 500) {
          return {
            backgroundImage: "none",
            minHeight: "100vh"
          };
        } else {
          return {
            backgroundImage: "none",
            height: "auto",
            minHeight: "500px"
          };
        }
      }
    }
  },
  mounted() {
    this.loadImageFromParams();
    // on window resize
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  methods: {
    onClickRegisterJobs: function() {
      this.$router.push("/jobseeker_register");
    },
    loadImageFromParams: function() {
      // declare taget
      let tgt;
      // check whether the target is stored in local storage
      if (localStorage.target === undefined) {
        tgt = this.$route.query.tgt ? this.$route.query.tgt : "default";
      } else {
        // set default target from url
        tgt = this.$route.query.tgt
          ? this.$route.query.tgt
          : localStorage.target;
      }
      // set local storage
      localStorage.target = tgt;
      // get url
      fetch(API + "/render/cover_image", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json"
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          target: tgt
        }) // body data type must match "Content-Type" header
      })
        .then(response => response.json())
        .then(data => {
          this.img = data.imgUrl;
        })
        .catch(err => {
          console.log(err);
        });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>

<style></style>
