<template>
  <header>
    <div :class="{active: !menu}" class="bg-white w-full h-screen fixed sm:hidden" id="menu">
      <div class="container mt-20 p-10 flex flex-col items-center">
        <div class="w-full max-w-xs">
          <for-employers-button v-if="button === 'for-employers'"/>
          <for-jobseekers-button v-if="button === 'for-jobseekers'"/>
          <sign-out-button v-if="button === 'sign-out'"/>
          <div class="links mt-10">
            <register-to-find-job-link/>
            <div class="mt-5"></div>
            <employers-link/>
            <div class="mt-5"></div>
            <terms-of-use-link/>
            <div class="mt-5"></div>
            <privacy-policy-link/>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-screen items-center justify-between pt-5 pb-5 sm:pb-5 lg:pb-5 h-auto flex-row sm:pt-5 sm:flex-row sm:justify-between bg-white" :style="{'--tw-bg-opacity': backgroundOpacity}">
      <div @click="onClickLogo" class="org flex items-center ml-5 bg-white border-mainColor border px-3 py-2 border-opacity-30 sm:ml-10 sm:px-4 sm:py-2 lg:px-5 lg:py-3  lg:ml-20 rounded cursor-pointer">
          <img src="@/assets/icons/logo.png" alt="" class="org_logo w-5 sm:w-6 md:w-8 lg:w-10 ">
          <div class="org_name ml-3 font-bold text-lg sm:text-lg lg:text-xl  font-koho">STRONG <span class="text-mainColor">UNITED</span></div>
      </div>
      <div class="hidden sm:block sm:mt-0 sm:mr-10 lg:mr-20" >
        <for-employers-button v-if="button === 'for-employers'"/>
        <for-jobseekers-button v-if="button === 'for-jobseekers'"/>
        <sign-out-button v-if="button === 'sign-out'"/>
      </div>
      <div class="pr-5 sm:hidden flex items-center">
        <tasty-burger-button @toggle="onToggleMenu" :color="hamburgerMenuColor" activeColor='black'/> 
      </div>
    </div>
  </header>
</template>

<script>
import { TastyBurgerButton } from 'vue-tasty-burgers'
import ForEmployersButton from './ForEmployersButton'
import PrivacyPolicyLink from './PrivacyPolicyLink'
import TermsOfUseLink from './TermsOfUseLink'
import EmployersLink from './EmployersLink'
import RegisterToFindJobLink from './RegisterToFindJobLink'
import ForJobseekersButton from '@/components/ForJobseekersButton'
import SignOutButton from '@/components/SignOutButton'

function normalize(val, max, min) {
  if(val >= max) {
    return 1
  }else{
    return (val - min) / (max - min)
  }
}

export default {
  props:{
    menuColor: {
      type: String,
      default: 'white'
    },
    button: {
      type: String,
      default: 'for-employers'
    }
  },
  data() {
    return {
      menu: false,
      backgroundOpacity: 0,
      hamburgerMenuColor: this.menuColor ? this.menuColor : 'white'
    }
  },
  components: {
    'tasty-burger-button': TastyBurgerButton,
    'for-employers-button': ForEmployersButton,
    'privacy-policy-link': PrivacyPolicyLink,
    'terms-of-use-link': TermsOfUseLink,
    'employers-link': EmployersLink,
    'register-to-find-job-link': RegisterToFindJobLink,
    'for-jobseekers-button': ForJobseekersButton,
    'sign-out-button': SignOutButton
  },
  methods: {
    onToggleMenu(active){
      this.menu = active
    },
    onClickLogo(){
      this.$router.replace('/').catch(()=>{
        // current route is already the home
      })
    },
    handleScroll(){
      var normalizedValue = normalize(window.pageYOffset, 250, 0)
      // if menu color is defined then, no need to animate opacity of the backround
      if(this.menuColor != '#A51111'){
        this.backgroundOpacity = normalizedValue
      }else{
        this.backgroundOpacity = 1
      }
      // no need to change menu color when defined in props
      if(normalizedValue <= 0.5){
        if(this.hamburgerMenuColor !== this.menuColor){
          this.hamburgerMenuColor = this.menuColor
        }
      }else{
        if(this.hamburgerMenuColor !== '#A51111'){
          this.hamburgerMenuColor = '#A51111'
        }
      }
    }
  },
  created() {
    document.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    document.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style>
.active{
  display: none;
}
</style>