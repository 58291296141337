<template>
    <div id="checkbox_wrapper">
        <label>
            <input
                v-bind="$attrs"
                v-bind:checked="checked"
                v-on="inputListeners"
                id="custom_checkbox"
            >
            <span id="check_border"></span>
            <span id="check"></span>
        </label>
    </div>
    
</template>

<script>
export default {
    inheritAttrs: false,
    model: {
        prop: 'checked',
        event: 'input'
    },
    props:{
        checked: {
            type: Boolean
        }
    },
    computed: {
        inputListeners: function () {
        var vm = this
        // `Object.assign` merges objects together to form a new object
        return Object.assign({},
            // We add all the listeners from the parent
                this.$listeners,
            // Then we can add custom listeners or override the
            // behavior of some listeners.
            {
            // This ensures that the component works with v-model
                input: function (event) {
                    vm.$emit('input', event.target.checked)
                }
            })
        }
    }
}
</script>

<style scoped>
#custom_checkbox{
    display: none;
}
#check{
    @apply block w-3.5 h-3.5 border-2 border-white border-solid;
    border-radius: 3px; 
}
#custom_checkbox:checked ~ #check{
    @apply bg-mainColor
}
#custom_checkbox:checked ~ #check_border{
    @apply border-mainColor
}
#checkbox_wrapper{
    @apply h-4 w-4 relative
}
#check_border{
    @apply border border-gray-400 h-4 w-4 absolute;
    top: -1px;
    left: -1px;
    border-radius: 3px;
}

</style>