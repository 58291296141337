<template>
  <div class="h-auto w-full" id="reputed_employers_section">
      <div class="flex flex-col w-full h-auto px-5 py-20 sm:px-10 items-center">
          <h1 class="font-koho text-black text-5xl font-medium text-center md:text-6xl lg:text-7xl" id="reputed_heading">
              Only reputed employers
          </h1>
          <p class="font-koho pt-10 text-lg text-black sm:text-center md:px-16 lg:text-xl lg:px-24" id="reputed_sub_paragraph">
              Our volunteers coordinate only with recruiters at reputed, domestic and multinational companies to understand their requirements and forward matching candidate profiles.
          </p>
          <div class="flex flex-col mt-14 h-auto w-full sm:flex-row sm:items-center sm:justify-center" id="job_types">
                <div class="flex flex-col h-auto w-full items-center sm:flex-row sm:px-10 sm:w-auto md:pr-16 lg:pr-28" id="authentic_job_types">
                    <img src="../assets/images/shield-check.svg" alt="" class="w-28 h-auto sm:w-20 md:w-24 lg:w-28" id="authentic_image">
                    <div class="w-full h-auto text-center mt-10 sm:text-left sm:ml-10 sm:mt-0" id="authentic_list">
                        <h3 class="authentic_list_element">
                            HUMAN RESOURCE JOBS
                        </h3>
                        <h3 class="authentic_list_element">
                            ACCOUNTING JOBS
                        </h3>
                        <h3 class="authentic_list_element">
                            CUSTOMER SUPPORT JOBS
                        </h3>
                        <h3 class="authentic_list_element">
                            DIGITAL MARKETING JOBS
                        </h3>
                        <h3 class="authentic_list_element">
                            DESIGNING JOBS
                        </h3>
                        <h3 class="authentic_list_element">
                            PROGRAMMING JOBS
                        </h3>
                    </div>
                </div>
                <div class="h-auto w-full mt-16 text-center sm:text-left sm:w-auto sm:mt-0" id="unauthentic_job_types">
                  <h1 class="font-koho text-5xl text-customRed font-medium sm:text-6xl" id="unauthentic_heading">
                      NO
                  </h1>
                  <div class="h-auto w-full mt-10 sm:mt-3" id="unauthentic_list">
                      <h3 class="unauthentic_list_element">
                          TELE-SALES JOBS
                      </h3>
                      <h3 class="unauthentic_list_element">
                          MLM JOBS
                      </h3>
                      <h3 class="unauthentic_list_element">
                          PYRAMID SCHEMES JOBS
                      </h3>
                      <h3 class="unauthentic_list_element">
                          UNETHICAL JOBS
                      </h3>
                  </div>
                </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.authentic_list_element{
    @apply text-mainColor text-lg mt-3 font-koho sm:mt-1 sm:text-base md:text-lg;
}
.unauthentic_list_element{
    @apply text-customRed text-lg mt-3 font-koho line-through sm:mt-1 sm:text-base md:text-lg;
}
</style>