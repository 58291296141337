<template>
  <div id="volunteer_success">
      <div class="heading">
        Thank you!
      </div>
      <div class="sub_heading">
        A volunteer will contact you soon.
      </div>
      <div class="logo">
        <img src="@/assets/images/check-circle.svg" class="success_image">
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
#volunteer_success{
  @apply w-full max-w-xs px-6 py-10 bg-white rounded shadow-lg flex flex-col justify-center items-center;
  @apply lg:px-12 lg:py-12 lg:max-w-sm;
  height: 500px;
}
#volunteer_success .success_image{
  @apply h-28 w-28 mt-5;
  @apply sm:h-20 sm:w-20 md:h-28 md:w-28;
}
#volunteer_success .heading{
  @apply text-2xl font-koho font-semibold;
  @apply sm:text-xl md:text-2xl;
}
#volunteer_success .sub_heading{
  @apply mt-4 font-koho font-light text-lg text-center;
  @apply sm:text-base md:text-lg;
}
</style>