<template>
    <div>
        <button class="w-full sm:w-auto text-lg sm:text-base md:text-lg lg:text-xl px-5 py-3 sm:px-3 sm:py-2 md:px-5 md:py-3 focus:bg-mainColor-dark hover:bg-mainColor-light shadow text-white font-koho rounded bg-mainColor font-extralight focus:outline-none" @click="onClick" id="register_button">REGISTER TO FIND JOBS</button>
    </div>
</template>

<script>
export default {
    props: {
        onClick: {type: Function}
    }
}
</script>

<style>

</style>