<template>
  <footer class="w-full">
      <div class="w-full pb-16 pt-28 pl-7 sm:p-5 md:p-10 lg:px-20 lg:py-12 sm:mb-0 sm:mt-0 sm:flex sm:flex-row sm:justify-between sm:items-center">
            <div class="font-koho text-xs text-gray-500 hidden sm:block lg:text-sm" id="copy_right">© 2021, STRONG UNITED</div>
            <div class="links sm:flex sm:flex-row sm:w-auto sm:justify-end">
                <div :class="{hidden: stripped}" id="register_link">
                    <register-to-find-job-link/>
                </div>
                <div class="mt-5 sm:mt-0 sm:pl-4 md:pl-7 lg:pl-12"></div>
                <div :class="{hidden: stripped}" id="employers_link">
                    <employers-link/>
                </div>
                <div class="mt-5 sm:mt-0 sm:pl-4 md:pl-7 lg:pl-12"></div>
                <terms-of-use-link/>
                <div class="mt-5 sm:mt-0 sm:pl-4 md:pl-7 lg:pl-12"></div>    
                <privacy-policy-link/>
                <div class="mt-5 sm:mt-0"></div>
            </div>
            <div class="font-koho text-xs text-gray-500 block sm:hidden" id="copy_right">© 2021, STRONG UNITED</div>
      </div>
  </footer>
</template>

<script>
import RegisterToFindJobLink from './RegisterToFindJobLink'
import EmployersLink from './EmployersLink'
import TermsOfUseLink from './TermsOfUseLink'
import PrivacyPolicyLink from './PrivacyPolicyLink'

export default {
    name: 'Footer',
    props: {
        stripped: {
            type: Boolean,
            default: false
        }
    },
    components: {
        'register-to-find-job-link': RegisterToFindJobLink,
        'employers-link': EmployersLink,
        'terms-of-use-link': TermsOfUseLink,
        'privacy-policy-link': PrivacyPolicyLink
    }
}
</script>
        TermsOfUse

<style>

</style>