<template>
  <div class="h-auto w-full" id="about_section">
      <div class="h-auto w-full min-h-screen px-5 py-20 sm:px-10 sm:py-20 md:px-14 md:py-16 lg:px-20 lg:py-24 bg-customGrayBlue-light flex flex-col items-center" id="content">
        <h3 class="font-koho text-xl font-medium text-center">YOUR AGE, GENDER OR WORK HISTORY<span class="text-customRed"> WILL NOT BE <span>BARRIERS</span></span></h3>
        <div class="mt-24 text-center absolute px-5 sm:mt-20" id="heading" >
            <animated-heading :headings="headings"/>
        </div>
        <div id="tiles" class="w-full h-auto grid grid-cols-1 grid-rows-2 gap-y-14 sm:gap-y-0 sm:grid-cols-2 sm:grid-rows-1 sm:gap-x-10 lg:gap-x-20 mt-44 sm:mt-64 lg:mt-52">
            <div class="bg-white px-5 py-8 sm:px-10 sm:py-14 md:px-14 md:py-16 lg:px-20 lg:py-20 rounded-lg border-r-2 border-b-2 border-black border-opacity-20" id="tileOne">
                <h3 class="font-koho text-mainColor text-sm font-normal md:text-base">WHAT IS STRONG UNITED?</h3>
                <p class="font-koho mt-5 text-base md:text-lg"><span class="text-customRed">Strong United</span> is a not-for-profit, volunteer-driven social service initiative founded on the belief that employment can solve many social problems.</p>
            </div>
            <div class="bg-white px-5 py-8 sm:px-10 sm:py-14 md:px-14 md:py-16 lg:px-20 lg:py-20 rounded-lg border-r-2 border-b-2 border-black border-opacity-20" id="tileOne">
                <h3 class="font-koho text-mainColor text-sm font-normal md:text-base">HOW WE HELP</h3>
                <p class="font-koho mt-5 text-base md:text-lg">Profiles of individuals are processed using open source recruitment management systems (RPMs) to match for relevance against job postings by reputed companies.</p>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import AnimatedHeadingFlip from './AnimatedHeadingFlip'
import API from '../API'

export default {
    data(){
        return {
            headings: []
        }
    },
    components: {
        'animated-heading': AnimatedHeadingFlip
    },
    created() {
        // fetch the headings
        fetch(API+'/render/animated_text')
        .then(response => response.json())
        .then(data => {
            // sort the array based on display order
            data.headings.sort((a,b)=>{
                return a.displayOrder - b.displayOrder
            })
            // add headings to the state
            data.headings.map((item)=>{
                // push
                this.headings.push(item.heading)
            })
        })
        .catch((err)=>{
            console.log(err)
        })
    }
}
</script>

<style>

</style>