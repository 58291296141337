import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/styles/index.css'
const vSelect = () => import(/* webpackChunkName: "Utils" */'vue-select')
import 'vue-select/dist/vue-select.css'
import VueGtag from "vue-gtag"

Vue.component('v-select', vSelect)
Vue.config.productionTip = false


if(process.env.NODE_ENV === 'production'){
  // g tag
  Vue.use(VueGtag,{
    config: {
      id: 'G-FQ8GR9178S',
      params: {
        cookie_flags: 'SameSite=None;Secure'
      }
    }
  }, router);

  // Check that service workers are supported
  if ('serviceWorker' in navigator) {
    // Use the window load event to keep the page load performant
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/sw.js');
    });
  }
}


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
