<template>
  <div class="" id="volunteer_signup_form">
        <h1 class="" id="volunteer_signup_heading">BECOME A VOLUNTEER</h1>
        <input type="text" class="volunteer_inputbox placeholder-extralight" v-model="name" :class="{error_box: errorName.error}" id="volunteer_fullname" placeholder="Full name">
        <h3 class="error_message_absolute" :class="{hidden: !errorName.error}">{{errorName.msg}}</h3>
        <input type="text" class="volunteer_inputbox placeholder-extralight" v-model="email" :class="{error_box: errorEmail.error}" id="volunteer_email" placeholder="Email">
        <h3 class="error_message_absolute" :class="{hidden: !errorEmail.error}" >{{errorEmail.msg}}</h3>
        <input type="text" class="volunteer_inputbox placeholder-extralight" v-bind:value="mob" v-on:input="onInputMob" :class="{error_box: errorMob.error}" id="volunteer_mobile_number" placeholder="10-digit mobile number">
        <h3 class="error_message_absolute" :class="{hidden: !errorMob.error}">{{errorMob.msg}}</h3>
        <div class="contact_consent">
            <custom-checkbox type="checkbox" v-model="consentChecked"/>
            <div :class="{consent_error: errorConsentChecked.error}" id="consent_heading">
                I agree to be contacted via SMS and email
            </div>
        </div>
        <div class="mt-6 sm:mt-5 md:mt-7" id="signup_button">
            <signup-button :onClick="onClickSignUp"/>
        </div>
  </div>
</template>

<script>
import SignUpButton from '@/components/SignUpButton'
import API from '@/API'
import CustomCheckbox from '@/components/CustomCheckbox'

export default {
    name: 'volunteerSignUpForm',
    data(){
        return {
            consentChecked: false,
            name: "",
            email: "",
            mob: "",
            errorName: {
                error: false,
                msg: ""
            },
            errorEmail: {
                error: false,
                msg: ""
            },
            errorMob: {
                error: false,
                msg: ""
            },
            errorConsentChecked: {
                error: false
            }
        }
    },
    components: {
        'signup-button': SignUpButton,
        'custom-checkbox': CustomCheckbox,
    },
    methods: {
        onClickSignUp: function(){
            // check all the validation
            let nameValidation = this.validateName()
            let emailValidation = this.validateEmail()
            let mobValidation = this.validateMob()
            let consentValidation = this.validateChecked()
    
            if(nameValidation && emailValidation && mobValidation && consentValidation){
                // set data
                var data = {
                    mobile: parseInt(this.mob),
                    email: this.email,
                    fullname: this.name
                }
                // save volunteer to the database
                // save the form 
                fetch(API+'/volunteer/register',
                {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: JSON.stringify(data) // body data type must match "Content-Type" header
                })
                .then(response => response.json())
                .then(data => {
                    if(data.success === true){
                        // navigate to verification
                        if(this.$route.path !== '/'){
                            this.$router.push({path: this.$route.path + "/volunteer/verification", query:{mob: this.mob, email: this.email}})
                        }else{
                            this.$router.push({path: "/volunteer/verification", query:{mob: this.mob, email: this.email}})
                        }
                    }else if(data.success === false && data.msg === "mobile number already exists"){
                        // set error
                        this.errorMob = {
                            error: true,
                            msg: "Mobile number already exists"
                        }
                    }
                })
                .catch((err)=>{
                    console.log(err)
                })
            }
        },
        validateName: function(){
            // special character detection format
            let specialCharacterFormat = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
            let digitFormat = /\d/

            if(this.name === ""){
                this.errorName.error = true
                this.errorName.msg = "Enter your full name"
                return false
            }else if(specialCharacterFormat.test(this.name) || digitFormat.test(this.name)){
                this.errorName.error = true
                this.errorName.msg = "Enter a valid name"
                return false
            }else{
                this.errorName.error = false
                this.errorName.msg = ""
                return true
            }
        },
        validateEmail: function(){
            // email validation
            var validEmailFormat  = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

            if(this.email === ""){
                this.errorEmail.error = true
                this.errorEmail.msg = "Enter your email"
                return false
            }else if(!validEmailFormat.test(this.email)){
                this.errorEmail.error = true
                this.errorEmail.msg = "Enter a valid email"
                return false
            }else{
                this.errorEmail.error = false
                this.errorEmail.msg = ""
                return true
            }
        },
        validateMob: function(){
            if(this.mob === ""){
                this.errorMob.error = true
                this.errorMob.msg = "Enter your mobile number"
                return false
            }else{
                if(isNaN(parseInt(this.mob))){
                    this.errorMob.error = true
                    this.errorMob.msg = "Enter a valid mobile number"
                    return false
                }else if(this.mob.length !== 10){
                    this.errorMob.error = true
                    this.errorMob.msg = "Enter a 10 digit mobile number"
                    return false
                }else{
                    this.errorMob.error = false
                    this.errorMob.msg = ""
                    return true
                }
            }
        },
        onInputMob: function(e){
            //stop the input when input length reaches 10
            if(e.target.value.replace(/\D/g,'').length <= 10){
                // strip characters
                var strippedMob = e.target.value.replace(/\D/g,'')
                // if the first value is 0, delete it
                if(strippedMob[0] === '0'){
                    // slice the 0 
                    strippedMob =  strippedMob.slice(1)
                }
                this.mob = strippedMob
            }
            // the above equates to an empty string if the input is only characters, vue's reactivity will not update, so force update on every input
            this.$forceUpdate();
        },
        validateChecked: function(){
            if(this.consentChecked === false){
                this.errorConsentChecked.error = true
                return false
            }else{
                this.errorConsentChecked.error = false
                return true
            }
        }
    },
    watch:{
        name: function(){
            // validate
            this.validateName()
        },
        email: function(){
            // validate
            this.validateEmail()
        },
        mob: function(){
            // validate
            this.validateMob()
        },
        consentChecked: function(){
            // validate
            this.validateChecked()
        }
    }
}
</script>

<style>
#volunteer_signup_form{
    @apply w-full max-w-xs h-auto px-6 py-10 bg-white rounded shadow-lg;
    @apply lg:px-12 lg:py-12 lg:max-w-sm;
}
#volunteer_signup_heading{
    @apply font-koho text-2xl font-semibold;
    @apply sm:text-lg;
    @apply md:text-xl;
    @apply lg:text-2xl;
}
#consent_heading{
    @apply font-koho text-xs font-medium ml-3;
}
.consent_error{
    @apply text-customRed !important;
}
.contact_consent{
    @apply flex flex-row mt-10;
}
</style>