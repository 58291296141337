<template>
  <div id="volunteer_verification">
        <h1 id="volunteer_verification_heading">BECOME A VOLUNTEER</h1>
        <div class="sub_heading">
            Please verify your contact info
        </div>
        <div class="otp_section" id="mobile">
            <div class="otp_heading">
                OTP sent to your mobile
            </div>
            <div class="otp_input">
                <otp-input :verified="mobileOtpVerified" :error="onMobileError" @completed="onCompleteMobileOtp" @change="onChangeMobileOtp"/>
            </div>
            <div class="timer_resend_section mt-2 flex flex-row items-center absolute" :class="{hidden: !showMobileTimer}">
                <div class="count_down pt-0.5">
                    <timer @onEnd="onEndMobileTimer" @onChange="onChangeMobileTimer" :reset="resetMobileTimer"/>
                </div>
                <div class="resend" :class="{hidden: !showMobileResend}">
                    <button class="resend_button focus:outline-none font-koho underline ml-2 text-sm text-mainColor active:text-inputBorderGray" @click="onClickMobileResend">Resend</button>
                </div>
            </div>
        </div>
        <div class="otp_section" id="email">
            <div class="otp_heading">
                OTP sent to your email
            </div>
            <div class="otp_input">
                <otp-input :verified="emailOtpVerified" :error="onEmailError" @completed="onCompleteEmailOtp" @change="onChangeEmailOtp"/>
            </div>
            <div class="timer_resend_section mt-2 flex flex-row items-center absolute" :class="{hidden: !showEmailTimer}">
                <div class="count_down pt-0.5">
                    <timer @onEnd="onEndEmailTimer" @onChange="onChangeEmailTimer" :reset="resetEmailTimer"/>
                </div>
                <div class="resend" :class="{hidden: !showEmailResend}">
                    <button class="resend_button focus:outline-none font-koho underline ml-2 text-sm text-mainColor active:text-inputBorderGray" @click="onClickEmailResend">Resend</button>
                </div>
            </div>
        </div>
        <div class="verify_button">
            <signup-button text="VERIFY" :onClick="onClickVerify"/>
        </div>
  </div>
</template>

<script>
import SignUpButton from '@/components/SignUpButton'
import OTPInput from '@/components/OTPInput'
import Timer from '@/components/Timer'
import API from '@/API'

export default {
    name: 'VolunteerVerification',
    data(){
        return {
            mob: 0,
            email: '',
            showMobileTimer: false,
            showEmailTimer: false,
            resetMobileTimer: false,
            resetEmailTimer: false,
            showMobileResend: false,
            showEmailResend: false,
            mobileOtpVerified: false,
            emailOtpVerified: false,
            onMobileError: false,
            onEmailError: false,
            mobileTries: 0,
            emailTries: 0,
            mobileOtp: '',
            emailOtp: '',
            mobileTimer: 0,
            emailTimer: 0
        }
    },
    watch: {
        mobileTimer: function(newValue){
            if(newValue < 120 && newValue > 0){
                this.showMobileResend = true
            }
            // if value is 180 set resetTimer as false
            if(newValue > 170){
                this.showMobileResend = false
                this.resetMobileTimer = false
            }
        },
        emailTimer: function(newValue){
            if(newValue < 120 && newValue > 0){
                this.showEmailResend = true
            }
            // if value is 180 set resetTimer as false
            if(newValue > 170){
                this.showEmailResend = false
                this.resetEmailTimer = false
            }
        }
    },
    components: {
        'signup-button': SignUpButton,
        'otp-input': OTPInput,
        'timer': Timer
    },
    methods: {
        sendMobileOtp: function(mob){
            var data = {
                mobile: parseInt(mob)
            }
            // send otp
            fetch(API+'/otp/generate',
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            .then(response => response.json())
            .then(data => {
                if(data.success === true){
                    this.showMobileTimer = true
                    this.resetMobileTimer = true
                    this.mobileTries = 0
                }
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        sendEmailOtp: function(email){
            var data = {
                email: email
            }
            // send otp
            fetch(API+'/otp/email/generate',
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            .then(response => response.json())
            .then(data => {
                if(data.success === true){
                    this.showEmailTimer = true
                    this.resetEmailTimer = true
                    this.emailTries = 0
                }
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        onChangeMobileTimer: function(value){
            this.mobileTimer = value
        },
        onChangeEmailTimer: function(value){
            this.emailTimer = value
        },
        onEndMobileTimer: function(){
            console.log('mobile timer end')
        },
        onEndEmailTimer: function(){
            console.log('email timer end')
        },
        onClickMobileResend: function(){
            if(this.mobileOtpVerified !== true){
                this.sendMobileOtp(this.mob)
            }
        },
        onClickEmailResend: function(){
            if(this.emailOtpVerified !== true){
                this.sendEmailOtp(this.email)
            }
        },
        onClickVerify: function(){
            if(this.mobileOtpVerified && this.emailOtpVerified){
                // navigate to verification
                if(this.$route.path === '/for_employers/volunteer/verification'){
                    this.$router.push({path: '/for_employers/volunteer/success', query:{mob: this.mob, email: this.email}})
                }else{
                    this.$router.push({path: '/volunteer/success', query:{mob: this.mob, email: this.email}})
                }

            }else{
                // set error
                if(!this.mobileOtpVerified){
                    this.onMobileError = true
                }
                if(!this.emailOtpVerified){
                    this.onEmailError = true
                }
            }
        },
        onCompleteMobileOtp: function(value){
            if(this.mobileTries <= 2){
                // when 4 digits are completed
                this.mobileOtp = value
                // verify otp
                var data = {
                    mobile: parseInt(this.mob),
                    otp: this.mobileOtp
                }
                fetch(API+'/otp/validate',
                {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: JSON.stringify(data) // body data type must match "Content-Type" header
                })
                .then(response => response.json())
                .then(data => {
                    if(data.success === true){
                        this.mobileOtpVerified = true
                        this.showMobileTimer = false
                        // send email otp
                        
                    }else{
                        this.onMobileError = true
                        this.mobileTries ++
                    }
                })
                .catch((err)=>{
                    console.log(err)
                })
            }else{
                // show error
                this.onMobileError = true
            }
        },
        onCompleteEmailOtp: function(value){
             if(this.emailTries <= 2){
                // when 4 digits are completed
                this.emailOtp = value
                // verify otp
                var data = {
                    email: this.email,
                    otp: this.emailOtp
                }
                fetch(API+'/otp/email/validate',
                {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: JSON.stringify(data) // body data type must match "Content-Type" header
                })
                .then(response => response.json())
                .then(data => {
                    if(data.success === true){
                        this.emailOtpVerified = true
                        this.showEmailTimer = false
                    }else{
                        this.onEmailError = true
                        this.emailTries ++
                    }
                })
                .catch((err)=>{
                    console.log(err)
                })
            }else{
                // show error
                this.onEmailError = true
            }
        },
        onChangeMobileOtp: function(value){
            // This function is invoked on each digit input
            this.mobileOtp = value
            this.onMobileError = false
        },
        onChangeEmailOtp: function(value){
            // This function is invoked on each digit input
            this.emailOtp = value
            this.onEmailError = false
        }
    },
    mounted() {
        // get mob number from query param
        this.$nextTick().then(() => {
            this.mob = this.$route.query.mob
            this.email = this.$route.query.email
            // send otp when page loaded
            this.sendMobileOtp(this.mob)
            this.sendEmailOtp(this.email)
        });
    }
}
</script>

<style>
#volunteer_verification{
    @apply w-full max-w-xs h-auto px-6 py-10 bg-white rounded shadow-lg;
    @apply lg:px-12 lg:py-12 lg:max-w-sm;
}
#volunteer_verification_heading{
    @apply font-koho text-2xl font-semibold;
    @apply sm:text-lg;
    @apply md:text-xl;
    @apply lg:text-2xl;
}
#volunteer_verification .sub_heading{
    @apply font-normal mt-5 text-left;
}
#volunteer_verification .otp_section{
    @apply mt-8
}
#volunteer_verification .otp_input{
    @apply mt-2
}
#volunteer_verification .verify_button{
    @apply mt-12
}
#volunteer_verification #email{
    @apply mt-12
}
#volunteer_verification .otp_heading{
    @apply font-koho font-medium text-lg;
    @apply text-base;
    @apply md:text-lg;
}
</style>