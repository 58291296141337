<template>
  <div id="app">
      <!-- if the form is not submitted yet -->
      <keep-alive
          :include="cacheEnabledComponentsList"
          v-if="keptAlive">
          <router-view></router-view>
      </keep-alive>
      <!-- otherwise don't keep anything alive -->
      <router-view v-else></router-view>
  </div>
</template>
<script>

  export default {
    name: 'App',
    data(){
      return {
        keptAlive: true,
        cacheEnabledComponentsList: [
          'BasicInfo',
          'NecessaryHardware',
          'EducationalQualification',
          'WorkExperiencePartOne',
          'WorkExperiencePartTwo',
          'LanguageProficiencyReadWrite',
          'LanguageProficiencySpeak',
          'JobPreferences',
          'PersonalInformation'
        ]
      }
    },
    beforeUpdate(){
      // when the current route is Home reload cache
      if(this.$router.currentRoute.name == 'Home'){
        // reload kept alive
        this.keptAlive = true
      }
      // when the current route is RegistartionComplete remove cache
      if(this.$router.currentRoute.name == 'RegistartionComplete'){
        // remove kept alive
        this.keptAlive = false
      }
    }
  }
</script>
<style>

</style>
