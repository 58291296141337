<template>
    <div>
        <button id="next_button" @click="onClickNext" :class="{inactive: !active}">{{text}}</button>
    </div>
</template>

<script>
export default {
    name: "NextButton",
    props: {
        onClick: {
            type: Function
        },
        active: {
            type: Boolean,
            default: true
        },
        text: {
            type: String,
            default: 'NEXT'
        }
    },
    methods: {
        onClickNext: function(){
            if(this.active === true){
                this.onClick()
            }
        }
    }
}
</script>

<style>
#next_button {
    @apply font-koho text-lg text-center w-full text-white py-3 bg-mainColor rounded border-b-2 border-r-2 border-mainColor-extralight active:bg-mainColor-extralight hover:bg-mainColor-dark focus:outline-none;
    @apply sm:text-sm sm:py-2;
    @apply md:text-base md:py-3;
    @apply lg:text-lg;
}
.inactive{
    @apply bg-inputBorderGray active:bg-inputBorderGray hover:bg-inputBorderGray border-white !important;
}
</style>