<template>
  <div id="animated_heading" :style="animate">
    {{headings[currentHeadingIndex]}}
  </div>
</template>

<script>

function elementInViewport(el) {
  var top = el.offsetTop;
  var height = el.offsetHeight;

  while(el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
  }

  return (
    top >= window.pageYOffset &&
    (top + height) <= (window.pageYOffset + window.innerHeight)
  );
}

export default {
    name:'AnimatedHeading',
    data() {
      return {
        currentHeadingIndex: 0,
        animateFlip: false,
      }
    },
    props: {
      headings: {type: Array}
    },
    created() {
      document.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
      document.removeEventListener('scroll', this.handleScroll);
    },
    computed: {
      animate(){
        if(this.animateFlip === true){
          return {
            transform: 'rotateX(360deg)',
            transition: 'transform .6s',
          }
        }else{
          return {

          }
        }
      }
    },
    methods: {
      handleScroll: function(){
        const el = document.getElementById("animated_heading")
        if(elementInViewport(el)) {
          document.removeEventListener('scroll', this.handleScroll)
          setTimeout(()=>{
            this.cycleHeading()
          },1000)
        }
      },
      cycleHeading: function(){
        //execute animation
        this.animateFlip = true
        // update headng
        if(this.currentHeadingIndex < (this.headings.length-1)){
          this.currentHeadingIndex ++
        }else if(this.currentHeadingIndex === (this.headings.length-1)){
          this.currentHeadingIndex = 0
        }
        setTimeout(()=>{
          this.animateFlip = false
          setTimeout(()=>{
            this.cycleHeading()
          },1000)
        },600)
      }
    }
}
</script>

<style>
#animated_heading{
  @apply font-koho font-medium text-4xl sm:text-6xl md:text-7xl;
}
</style>