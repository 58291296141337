<template>
  <div id="steps"><span class="" id="step">STEP {{step}}</span> OF 9</div>
</template>

<script>
export default {
    props: {
        step: {
            type: Number
        }
    }
}
</script>
<style>
#steps{
    @apply font-koho font-normal text-gray-500 text-sm mt-8 mb-8;
    @apply md:mt-0 md:mb-6
}
#step{
    @apply text-mainColor text-xl 
}
</style>