<template>
  <div id="basic_info">
        <div class="fixed z-50 top-0">
            <Header menuColor="#A51111"/>
        </div>
        <div class="register_content" id="basic_info_content">
            <div class="left_section">
                <div class="left_section_heading">
                    Register to find WFH jobs
                </div>
                <div class="left_section_sub_heading">
                    THIS IS A FREE SERVICE
                </div>
                <div class="left_section_sub_para">
                    Your profile will be matched with work-
                    from-home job opportunities at various 
                    companies. Recruiters at these 
                    companies will contact you directly for 
                    further interviews.
                </div>
            </div>
            <div class="right_section">
                <steps :step="1"/>
                <div class="" id="basic_info_inputs">
                    <div class="flex flex-col md:flex-row md:items-center">
                        <div class="" id="first_name_section">
                            <div class="input_heading">
                                Your first name
                            </div>
                            <input type="text" class="inputbox" id="first_name_input" placeholder="First name" v-model="first_name" :class="{error_box: errorFirstName.error}">
                            <h3 class="error_message_absolute" :class="{hidden: !errorFirstName.error}">{{errorFirstName.msg}}</h3>
                        </div>
                        <div class="" id="last_name_section">
                            <div class="input_heading">
                                Your last name
                            </div>
                            <input type="text" class="inputbox" id="last_name_input" placeholder="Last name" v-model="last_name" :class="{error_box: errorLastName.error}">
                            <h3 class="error_message_absolute" :class="{hidden: !errorLastName.error}">{{errorLastName.msg}}</h3>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:items-center">
                        <div class="" id="mob_section">
                            <div class="input_heading">
                                Your 10-digit mobile number
                            </div>
                            <input type="text" class="inputbox" id="mob_input" placeholder="Mobile number" v-bind:value="mob" v-on:input="onInputMob" :class="{error_box: errorMob.error}">
                            <h3 class="error_message_absolute" :class="{hidden: !errorMob.error}">{{errorMob.msg}}</h3>
                        </div>
                        <div class="" id="email_section">
                            <div class="input_heading">
                                Your email address
                            </div>
                            <input type="text" class="inputbox" id="email_input" placeholder="Email address" v-model="email"  :class="{error_box: errorEmail.error}">
                            <h3 class="error_message_absolute" :class="{hidden: !errorEmail.error}">{{errorEmail.msg}}</h3>
                        </div>
                    </div>
                </div>
                <div class="" id="disclaimer">
                    Your contact information will be shared with potential employers. It will NOT be shared with or sold to any third party to be used for any other purpose under any circumstance.
                </div>
                <div class="" id="consent">
                    <div class="mt-1.5"><custom-checkbox type="checkbox" v-model="checked" /></div>
                    <div :class="{consent_error: errorChecked.error}" id="consent_heading">
                        By continuing, you agree to be contacted by us or potential employers over phone, SMS, and / or email regarding employment.
                    </div>
                </div>
                <div id="next_button_section" class="md:max-w-xs">
                    <next-button :onClick="onClickNext" :active="activateNext"/>
                </div>
            </div>
        </div>
        <Footer :stripped="true"/>
  </div>
</template>

<script>
import Steps from "@/components/Steps"
import Header from "@/components/Header"
import Footer from "@/components/Footer"
import NextButton from "@/components/NextButton"
import CustomCheckbox from '@/components/CustomCheckbox'
import API from '../../API'

export default {
    name: "BasicInfo",
    components: {
        Header,
        Footer,
        'steps': Steps,
        'next-button': NextButton,
        'custom-checkbox': CustomCheckbox
    },
    data(){
        return {
            checked: false,
            first_name: "",
            last_name: "",
            mob: "",
            email: "",
            errorFirstName: {
                error: false,
                msg: ""
            },
            errorLastName: {
                error: false,
                msg: ""
            },
            errorMob: {
                error: false,
                msg: ""
            },
            errorEmail: {
                error: false,
                msg: ""
            },
            errorChecked: {
                error: false
            },
            activateNext: false
        }
    },
    computed: {
        activateNextButton: function(){
            return this.first_name,this.last_name,this.email,this.mob,this.checked, Date.now();
        }
    },
    methods: {
        onClickNext: function(){
            // disable the button
            this.activateNext = false
            // set data
            var data = {
                mobile: parseInt(this.mob),
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email
            }
            // save the form 
            fetch(API+'/jobseeker/basic_info',
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            .then(response => response.json())
            .then(data => {
                if(data.success === true){
                    this.activateNext = true
                    this.$router.push({path: "/jobseeker_register/necessary_hardware", query:{mob: this.mob}})
                }else if(data.success === false && data.msg === "mobile number already exists"){
                    // set error
                    this.errorMob = {
                        error: true,
                        msg: "Mobile number already exists"
                    }
                }
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        onInputMob: function(e){
            //stop the input when input length reaches 10
            if(e.target.value.replace(/\D/g,'').length <= 10){
                // strip characters
                var strippedMob = e.target.value.replace(/\D/g,'')
                // if the first value is 0, delete it
                if(strippedMob[0] === '0'){
                    // slice the 0 
                    strippedMob =  strippedMob.slice(1)
                }
                this.mob = strippedMob
            }
            // the above equates to an empty string if the input is only characters, vue's reactivity will not update, so force update on every input
            this.$forceUpdate();
        },
        validateFirstName: function(){
            // special character detection format
            let specialCharacterFormat = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
            let digitFormat = /\d/

            if(this.first_name === ""){
                this.errorFirstName.error = true
                this.errorFirstName.msg = "Enter your first name"
                return false
            }else if(specialCharacterFormat.test(this.first_name) || digitFormat.test(this.first_name)){
                this.errorFirstName.error = true
                this.errorFirstName.msg = "Enter a valid name"
                return false
            }else{
                this.errorFirstName.error = false
                this.errorFirstName.msg = ""
                return true
            }
        },
        validateLastName: function(){
            // special character detection format
            let specialCharacterFormat = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
            let digitFormat = /\d/

            if(this.last_name === ""){
                this.errorLastName.error = true
                this.errorLastName.msg = "Enter your last name"
                return false
            }else if(specialCharacterFormat.test(this.last_name) || digitFormat.test(this.last_name)){
                this.errorLastName.error = true
                this.errorLastName.msg = "Enter a valid name"
                return false
            }else{
                this.errorLastName.error = false
                this.errorLastName.msg = ""
                return true
            }
        },
        validateMob: function(){
            if(this.mob === ""){
                this.errorMob.error = true
                this.errorMob.msg = "Enter your mobile number"
                return false
            }else{
                if(isNaN(parseInt(this.mob))){
                    this.errorMob.error = true
                    this.errorMob.msg = "Enter a valid mobile number"
                    return false
                }else if(this.mob.length !== 10){
                    this.errorMob.error = true
                    this.errorMob.msg = "Enter a 10 digit mobile number"
                    return false
                }else{
                    this.errorMob.error = false
                    this.errorMob.msg = ""
                    return true
                }
            }
        },
        validateEmail: function(){
            // email format in regex code
            var validEmailFormat  = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

            if(this.email === ""){
                this.errorEmail.error = true
                this.errorEmail.msg = "Enter your email"
                return false
            }else if(!validEmailFormat.test(this.email)){
                this.errorEmail.error = true
                this.errorEmail.msg = "Enter a valid email"
                return false
            }else{
                this.errorEmail.error = false
                this.errorEmail.msg = ""
                return true
            }
        },
        validateChecked: function(){
            if(this.checked === false){
                this.errorChecked.error = true
                return false
            }else{
                this.errorChecked.error = false
                return true
            }
        }
    },
    watch: {
        first_name: function(){
            this.validateFirstName()
        },
        last_name: function(){
            this.validateLastName()
        },
        email: function(){
            this.validateEmail()
        },
        mob: function(){
            this.validateMob()
        },
        checked: function(){
            this.validateChecked()
        },
        activateNextButton: function(){
            // check if there is no error
            if(!this.errorFirstName.error && !this.errorLastName.error && !this.errorMob.error && !this.errorEmail.error && !this.errorChecked.error){
                // check if all the feilds are filled
                if(this.first_name !== "" && this.last_name !== "" && this.mob !== "" && this.email !== "" && this.mob !== "" && this.checked == true){
                    this.activateNext = true
                }
            }else{
                this.activateNext = false
            }
        }
    },
    mounted(){
        // scroll back to top when mounted
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    }
}
</script>

<style scoped>
#basic_info{
    @apply md:h-screen md:w-screen md:flex md:flex-col md:flex-grow-0 md:flex-shrink-0;
}
#header{
    @apply w-full h-auto min-h-screen;
}
#left_section_heading{
    @apply font-koho text-xl;
}
#last_name_section{
    @apply mt-6;
    @apply md:mt-0 md:ml-5;
    @apply lg:ml-10;
}
#mob_section{
    @apply mt-6;
    @apply md:mt-8;
    @apply lg:mt-10;
}
#email_section{
    @apply mt-6;
    @apply md:ml-5 md:mt-8;
    @apply lg:ml-10 lg:mt-10;
}
#disclaimer{
    @apply mt-10 font-koho text-xs;
}
#next_button_section{
    @apply mt-12;
}
#consent{
    @apply flex flex-row mt-4 items-start;
}
#consent_heading{
    @apply font-koho text-xs font-medium ml-3;
}
.consent_error{
    @apply text-customRed !important;
}
</style>