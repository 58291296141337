<template>
    <div class="timer flex flex-row font-koho text-customRed text-sm">
        {{ countDown }} seconds
    </div>
</template>

<script>
    export default {
        props: {
            reset: {
                type: Boolean
            }
        },
        data() {
            return {
                countDown: 180
            }
        },
        methods: {
            countDownTimer: function() {
                if(this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.countDownTimer()
                    }, 1000)
                }
            }
        },
        created() {
           this.countDownTimer()
        },
        watch: {
            countDown: function(newValue, oldValue){
                if(newValue === 0){
                    this.$emit('onEnd', '')
                }else if(newValue === 180 && oldValue === 0){
                    this.countDownTimer()
                }else{
                    this.$emit('onChange', newValue)
                }
            },
            reset: function(newValue, oldValue){
                if(newValue !== oldValue){
                    if(newValue === true){
                        this.countDown = 180
                    }
                }
            }
        }
    }
</script>