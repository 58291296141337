<template>
    <div>
        <button id="sign_up_button" @click="onClick">{{text}}</button>
    </div>
</template>

<script>
export default {
    name: "SignUpButton",
    props: {
        onClick: {
            type: Function
        },
        text: {
            type: String,
            default: 'SIGN UP'
        }
    }
}
</script>

<style>
#sign_up_button {
    @apply font-koho text-lg text-center w-full text-white py-3 bg-customRed rounded border-b-2 border-r-2 border-customRedBorder active:bg-customRedBorder hover:bg-customRed-light focus:outline-none;
    @apply sm:text-sm sm:py-2;
    @apply md:text-base md:py-3;
    @apply lg:text-lg;
}
</style>