<template>
  <button id="sing_out_button" @click="onClick" class="w-full sm:w-auto py-3 sm:py-2 sm:px-4 lg:px-5 lg:py-3 font-koho font-medium sm:mt-0 sm:text-base lg:text-lg text-customRed bg-white hover:bg-customRed hover:text-white focus:outline-none focus:bg-customRed-light focus:text-white border-customRed border border-opacity-30 rounded-md">
    SIGN OUT
  </button>
</template>

<script>
export default {
    methods: {
        onClick: function(){
          if(this.$route.path !== '/for_employers'){
            // delete the token
            localStorage.access_token = ''
            // navigate
            this.$router.push('/for_employers')
          }
        }
    }
}
</script>

<style>

</style>