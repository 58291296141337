<template>
  <button id="for_jobseekers_button" @click="onClick" class="w-full sm:w-auto py-3 sm:py-2 sm:px-4 lg:px-5 lg:py-3 font-koho font-medium sm:mt-0 sm:text-base lg:text-lg text-mainColor sm:text-customRed bg-white hover:bg-mainColor sm:hover:bg-customRed hover:text-white focus:outline-none focus:bg-mainColor-light sm:focus:bg-customRed-light focus:text-white border-mainColor sm:border-customRed border border-opacity-30 rounded-md">
    FOR JOBSEEKERS
  </button>
</template>

<script>
export default {
    methods: {
        onClick: function(){
          if(this.$route.path !== '/jobseeker_register'){
            this.$router.push('/jobseeker_register')
          }
        }
    }
}
</script>

<style>

</style>