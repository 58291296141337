<template>
  <div class="home h-auto flex flex-col">
    <div class="header fixed z-50">
      <Header/>
    </div>
    <div class="min-h-full min-w-full" id="content">
      <main-section/>
      <about-section/>
      <reputed-employers-section/>
      <volunteer-section/>
      <Footer/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header'
import MainSection from '@/components/MainSection'
import Footer from '@/components/Footer'
import AboutSection from '@/components/AboutSection'
import ReputedEmployersSection from '@/components/ReputedEmployersSection'
import VolunteerSection from '@/components/VolunteerSection'

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    'main-section': MainSection,
    'about-section': AboutSection,
    'reputed-employers-section': ReputedEmployersSection,
    'volunteer-section': VolunteerSection
  }
}
</script>

<style scoped>
</style>